<template>
  <div class="villages">
    <div class="villages__header">
      <div class="villages__header-row">
        <v-page-title class="villages__title">
          Коттеджные поселки
        </v-page-title>

        <v-button primary class="villages__button villages__button--add" @click="addVillage">
          <v-icon-plus class="villages__icon" />
          Добавить Поселок
        </v-button>
      </div>

      <div class="villages__header-row">
        <div class="villages__tabs-wrapper">
          <v-tabs :value="queryParams.status" class="villages__tabs" @input="changeStatus">
            <v-tab v-for="(villageStatus, index) of villageStatuses" :key="index" :name="villageStatus.value">
              {{ villageStatus.label }}
            </v-tab>
          </v-tabs>
        </div>

        <v-searchrow :value="queryParams.query" class="villages__searchrow" @search="search" />
      </div>
    </div>

    <div class="villages__list">
      <villages-list-item
        v-for="village in villagesList"
        :key="village.id"
        v-bind="village"
        @edit="editVillage(village)"
        @remove="deleteVillage(village)"
        @archive="archiveVillage(village)"
        @activate="activateVillage(village)"
      />
    </div>

    <div class="villages__pagination">
      <v-pagination :current="queryParams.page" :total="totalPages" @change="changePage" />
    </div>
  </div>
</template>

<script>
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import VButton from '@/components/common/VButton.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import VPagination from '@/components/common/VPagination.vue'
import VillagesListItem from '@/components/Villages/ListItem.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { villagesService } from '@/services/http'
import { loadingService } from '@/services/loading'
import redirectIfNetworkIssue from '@/router/utils'
import { VILLAGE_ACTIVE_STATUS, VILLAGE_DRAFT_STATUS, VILLAGE_ARCHIVE_STATUS } from '@/constants/statuses/village'

const STATUSES_MAP = [
  { label: 'Актуальное', value: VILLAGE_ACTIVE_STATUS },
  { label: 'Черновики', value: VILLAGE_DRAFT_STATUS },
  { label: 'Архив', value: VILLAGE_ARCHIVE_STATUS }
]

export default {
  name: 'Villages',
  components: {
    VPageTitle,
    VillagesListItem,
    VTabs,
    VTab,
    VSearchrow,
    VButton,
    VIconPlus,
    VPagination
  },
  data() {
    return {
      villageStatuses: STATUSES_MAP,

      loading: false,
      list: [],
      params: {
        limit: 15,
        count: 0
      }
    }
  },
  computed: {
    villagesList() {
      return this.list.map(el => ({ ...el, status: this.queryParams.status }))
    },
    totalPages() {
      return Math.ceil(this.params.count / this.params.limit)
    },
    queryParams() {
      return {
        page: Number(this.$route.query.page) || 1,
        query: this.$route.query.query || undefined,
        status: this.$route.query.status || VILLAGE_ACTIVE_STATUS
      }
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchVillages()
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.fetchVillages()
    })
    next()
  },
  methods: {
    updateQueryParams({ page, query, status }) {
      const params = { page, query, status }
      this.$router.push({ query: params })
    },
    fetchVillages(params) {
      const fetchParams = { ...this.queryParams, ...params }

      const { limit } = this.params
      const offset = (fetchParams.page - 1) * limit

      this.loading = true
      villagesService
        .getList({ limit, offset, query: fetchParams.query, status: fetchParams.status })
        .then(({ count, results }) => {
          this.list = results
          this.params = { ...this.params, count }
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
        })
    },
    search(query) {
      this.updateQueryParams({ ...this.queryParams, query })
    },
    changePage(page) {
      this.updateQueryParams({ ...this.queryParams, page })
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    addVillage() {
      this.$router.push({ name: 'villages-edit' })
    },
    editVillage(village) {
      this.$router.push({ name: 'villages-edit', params: { villageId: village.id } })
    },

    deleteVillage(village) {
      confirmService
        .ask({ title: 'Вы точно хотите удалить коттеджный поселок?' })
        .then(() => {
          this.loading = true
          villagesService
            .delete(village.id)
            .then(() => {
              this.fetchVillages()
              noticeService.success('Коттеджный поселок удален!')
            })
            .catch(() => noticeService.error('Не удалось удалить коттеджный поселок!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },

    archiveVillage(village) {
      confirmService
        .ask({ title: 'Вы точно хотите архивировать коттеджный поселок?' })
        .then(() => {
          this.loading = true
          villagesService
            .update(village.id, { status: VILLAGE_ARCHIVE_STATUS })
            .then(() => {
              this.fetchVillages()
              noticeService.success('Коттеджный поселок архивирован!')
            })
            .catch(() => noticeService.error('Не удалось архивировать коттеджный поселок!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },

    activateVillage(village) {
      confirmService
        .ask({ title: 'Вы точно хотите активировать коттеджный поселок?' })
        .then(() => {
          this.loading = true
          villagesService
            .update(village.id, { status: VILLAGE_ACTIVE_STATUS })
            .then(() => {
              this.fetchVillages()
              noticeService.success('Коттеджный поселок активирован!')
            })
            .catch(() => noticeService.error('Не удалось активировать коттеджный поселок!'))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
